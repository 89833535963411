import * as React from "react"
import FullApp from "../FullApp";
import Survey from "../components/pages/survey";
import Layout from "../templates/layout";
import UserPanel from "../components/userpanel";

export default (props) => (
    <FullApp>
        <Layout {...props} router={props} isDark={true}>
          <UserPanel {...props}>
            <Survey {...props} />
          </UserPanel>
        </Layout>
    </FullApp>
);