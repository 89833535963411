import * as React from "react";
import '../../css/survey.css';
import * as actions from "../../actions";
import {connect} from "react-redux";
import {
  Image, Segment, Container, Header, Input, Button, Message, Form, Radio, Progress, Checkbox, Divider
} from "semantic-ui-react";
import {ISurvey, IUser} from "../../types";
import {t} from "../../i18n";
import {navigateTo} from "../../helpers/router";
import _ from "lodash";
import {toast} from "react-toastify";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import SurveyAnimation from "../elements/surveyAnimation";
import PasswordStrengthBar from "react-password-strength-bar";
import SurveyBackground from "../backgrounds/survey/Survey";
import { IOrder } from '../../types/index';
import SEO from "../seo";
import { StaticImage } from 'gatsby-plugin-image';

const arrow_right = '/img/utils/arrow-right.png';
const arrow_left = '/img/utils/arrow-left.png';
const bin = '../../img/bin.png';

interface StateProps {
  user: IUser
  survey: ISurvey
  create: ISurvey
  nextAnswer: ISurvey
  prevAnswer: ISurvey
  order: IOrder
}

interface OwnProps {
  isLogged: boolean
}

export function mapStateToProps(state: any): StateProps {
  return {
    user: state.user,
    survey: state.surveyActions,
    create: state.createSurvey,
    nextAnswer: state.addAnswerToSurvey,
    prevAnswer: state.previousAnswerToSurvey,
    order: state.createSurveyOrder
  };
}

interface DispatchProps {
  createSurvey: typeof actions.createSurvey,
  getUserSurvey: typeof actions.getUserSurvey,
  addAnswerToSurvey: typeof actions.addAnswerToSurvey,
  previousAnswerToSurvey: typeof actions.previousAnswerToSurvey,
  createSurveyOrder: typeof actions.createSurveyOrder,
  getSurvey: typeof actions.getSurvey,
  getUser: typeof actions.fetchUser,
}

const mapDispatchToProps = {
  createSurvey: actions.createSurvey,
  addAnswerToSurvey: actions.addAnswerToSurvey,
  previousAnswerToSurvey: actions.previousAnswerToSurvey,
  getUserSurvey: actions.getUserSurvey,
  createSurveyOrder: actions.createSurveyOrder,
  getSurvey: actions.getSurvey,
  getUser: actions.fetchUser,
};

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  userId: null,
  userSurveyCode: string,
  currentQuestion: string,
  questionValue: string,
  questionText: string,
  type: string,
  value: string,
  nextActions: any,
  questionCode: string,
  surveyStatus: string,
  errors: string,
  isError: boolean,
  loading: boolean,
  progress: number,
  animationKey: number,
  answers: Array<any>,
  btnNextDisable: boolean,
  btnPrevDisable: boolean,
  showProgressBar: boolean,
  requiredPositions: Array<any>,
  blockPremixButton: boolean,
  password: string,
  waiting: boolean
}

class Survey extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userId: null,
      userSurveyCode: "",
      currentQuestion: "",
      questionValue: "",
      questionText: "",
      type: "",
      value: "",
      nextActions: {},
      questionCode: "",
      surveyStatus: "",
      errors: "",
      isError: false,
      loading: true,
      progress: 0,
      animationKey: 1,
      answers: [],
      btnNextDisable: false,
      btnPrevDisable: false,
      showProgressBar: true,
      requiredPositions: [],
      blockPremixButton: true,
      password: '',
      waiting: true
    }
  }

  componentDidMount(): void {
    let user = this.props.user;
    this.onUserChange(user);
    document.addEventListener("keydown", this.hitEnter, false);
    if (typeof window !== `undefined`) {
      window.dataLayer = window.dataLayer || [];
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.hitEnter, false);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.survey !== this.props.survey) {
      let survey = this.props.survey;
      this.setSurvey(survey);
      if (survey.auth) {
        this.props.getUser();
      }
    }
    if (prevProps.create !== this.props.create) {
      let survey = this.props.create;
      if (survey.data) {
        this.setState({surveyStatus: survey.data.status})
        if (typeof window !== `undefined`) {
          localStorage.setItem("surveyCode", survey.data.code);
        }
        this.setSurvey(survey);
      }
    }
    if (prevState.nextActions !== this.state.nextActions) {
      this.layerPush(this.state.nextActions.code);
      if (this.state.nextActions.hint) {
        this.setState({
          value: this.state.nextActions.hint
        })
      } else {
        this.setState({value: null})
      }
      this.createListOfRequiredFields();
    }
    if (prevProps.nextAnswer !== this.props.nextAnswer) {
      this.setState({btnNextDisable: false}
      )
    }
    if (prevProps.prevAnswer !== this.props.prevAnswer) {
      this.setState({btnPrevDisable: false}
      )
    }
    if(prevProps.order !== this.props.order){
      if(this.props.order.data){
        localStorage.setItem('surveyOrderCode', this.props.order.data.code);
        localStorage.setItem('order', 'survey');
        navigateTo("/checkorder")
      }
    }
  }

  layerPush(question) {
    if (window.dataLayer === undefined) {
      console.error('missing datalayer')
    } else {
      window.dataLayer.push({
        'event': 'ankieta',
        'step': question
      })
    }
  }

  createListOfRequiredFields = () => {
    if (!this.state.nextActions.validation) {
      return
    }
    let radioValues = this.state.nextActions.validation.split(new RegExp("[,|]"));
    this.setState({
      requiredPositions: radioValues.filter(value => this.isRequired(value))
    })
  }

  areAllRequiredFieldsChecked = (list) => {
    const requiredList = this.state.requiredPositions.map(item => item.slice(1))
    return requiredList.every(value => list.includes(value));
  }

  toastCheckStatement() {
    toast.error("Musisz zaakceptować wymagane zgody", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  hitEnter = (event) => {
    const type = this.state.nextActions.type;
    if (event.keyCode === 13) {
      if (type === "statement" || type === "multi") {
        this.sendMulti()
      } else {
        this.answerNext()
      }
    }
  }

  isRequired = (element) => {
    return (element.charAt(0) === "*")
  }

  setSurvey(survey) {
    if (survey.data) {
      survey = survey.data;
      if (survey.status === 'finish_ok') {
        this.setState({showProgressBar: false}, () => this.props.getUser())
        this.layerPush('personalizujemy')
      }
      let currentSurveyItem = survey.survey_items.pop();
      this.setState({
        surveyStatus: survey.status,
        userSurveyCode: survey.code,
        currentQuestion: currentSurveyItem.question,
        questionValue: currentSurveyItem.value,
        questionText: currentSurveyItem.text,
      })
      if (survey.next_actions) {
        const action = this.filterNextActionsAndLeaveOnlyQuestions(survey.next_actions);
        this.setState({showProgressBar: true, progress: action.progress, nextActions: action})
      }
    }
    if (survey.errors) {
      this.setState({errors: survey.errors.answer})
    } else {
      this.refreshAnimationKey()
    }
  }

  filterNextActionsAndLeaveOnlyQuestions = (nextActions) => {
    const properTypes = ["string", "integer", "number", "enum", "password", "multi", "statement"]
    let properNextAction = {};
    nextActions.map(action => {
      if (action.type === "answer") {
        toast.info(t(action.code), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (properTypes.includes(action.type)) {
        properNextAction = action;
      } else {
        console.error(`Unknown type ${action.type}`)
      }
    })
    return properNextAction;
  }

  onUserChange(user) {
    if (user) {
      if (user.data) {
        if (user.data.survey_code) {
          this.props.getUserSurvey({userId: user.data.id})
        } else {
          let surveyCode = "";
          if (typeof window !== `undefined`) {
            surveyCode = localStorage.getItem("surveyCode");
          }
          if (surveyCode) {
            this.props.getSurvey({code: surveyCode});
          } else {
            this.props.createSurvey();
          }
        }
      } else {
        let surveyCode = "";
        if (typeof window !== `undefined`) {
          surveyCode = localStorage.getItem("surveyCode");
        }
        if (surveyCode) {
          this.props.getSurvey({code: surveyCode});
        } else {
          this.props.createSurvey();
        }
      }
    }
  }

  toastError() {
    toast.error("Aby przejść dalej - odpowiedz na pytanie", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  handleInputChange(e: any, data: any): void {
    this.setState({errors: null, value: e.target.value});
  }

  handleInputPassword(e: any, data: any): void {
    this.setState({errors: null, password: e.target.value});
  }

  answerNext(): void {
    if (this.state.value) {
      let data = {
        code: this.state.userSurveyCode,
        answer: this.state.value,
        question_code: this.state.currentQuestion
      };
      this.setState({value: "", btnNextDisable: true}, () => this.props.addAnswerToSurvey(data));
    }
  }

  answerPassword(): void {
    if (this.state.password) {
      let data = {
        code: this.state.userSurveyCode,
        answer: this.state.password,
        question_code: this.state.currentQuestion
      };
      this.setState({value: "", btnNextDisable: true}, () => this.props.addAnswerToSurvey(data));
    }
  }

  answerPrevious() {
    let data = {
      code: this.state.userSurveyCode,
      question_code: this.state.currentQuestion
    };
    this.setState({btnPrevDisable: true}, () => this.props.previousAnswerToSurvey(data));
  }

  handleMulti(e, data) {
    let a = this.state.answers;
    if (data.checked) {
      if (data.value.charAt(0) === "*") {
        a.push(data.value.substring(1))
      } else {
        a.push(data.value)
      }
    } else {
      let index = a.indexOf(data.value)
      a.splice(index, 1);
    }
    this.setState({answers: a})
  }

  sendMulti() {
    let answer = "";
    let a = this.state.answers;
    if (!this.areAllRequiredFieldsChecked(a)) {
      this.toastCheckStatement();
      return
    }
    if (a) {
      answer = a.join("|");
      let data = {
        code: this.state.userSurveyCode,
        answer: answer,
        question_code: this.state.currentQuestion
      };
      if (answer.length > 0) {
        this.props.addAnswerToSurvey(data)
        this.setState({answers: []})
      } else {
        this.toastError();
      }
    }
  }

  createOrderAndRedirect(): void {
    this.props.createSurveyOrder(this.state.userSurveyCode);
  }

  createSurvey() {
    if (this.state.userSurveyCode) {
      return
    } else if (this.state.loading) {
      return (<Segment loading={true}/>);
    } else {
      return (
        <Segment basic style={{width: "100%"}}>
          <Message warning header={'Dostępne tylko dla zalogowanych użytkowników'}/>
        </Segment>
      )
    }
  }

  refreshAnimationKey() {
    this.setState({
      animationKey: (this.state.animationKey + 1)
    })
  }


  waitingAnimation() {
    return (
      <>
        <SurveyAnimation/>
        <Segment basic textAlign='center'>
          <Header as="h1" className='header-1'>Jeszcze chwilka,</Header>
          <Header as="h2" className='header-2'>właśnie personalizujemy <br/>Twój suplement</Header>
          <Button disabled={this.state.blockPremixButton} secondary onClick={this.createOrderAndRedirect.bind(this)}
                  style={{margin: "10px"}}>Sprawdź swój suplement</Button>
          <Button style={{margin: "10px"}} onClick={() => this.props.createSurvey()}>
            Wypełnij ankietę ponownie</Button>
        </Segment>
      </>
    )
  }

  waitingEnded() {
    return (
      <>
        <Segment basic textAlign='center'>
          <Header as="h1" className='header-1'>Twój suplement jest już gotowy!</Header>
          <Button disabled={this.state.blockPremixButton} secondary onClick={this.createOrderAndRedirect.bind(this)}
                  style={{margin: "10px"}}>Sprawdź swój suplement</Button>
          <Button style={{margin: "10px"}} onClick={() => this.props.createSurvey()}>
            Wypełnij ankietę ponownie</Button>
        </Segment>
      </>
    )
  }

  makeSurveyQuestion() {
    if (this.state.surveyStatus === 'finish_error') {
      return (
        <div id="survey-slide"><StaticImage src={bin} alt="Suplementy" />
          <Segment basic textAlign='center'>
            <Header as="h1">Błąd ankiety, przesłano niepoprawne wartości</Header>
            <Button primary onClick={this.props.createSurvey.bind(this)}>Wypełnij ankietę ponownie</Button>
          </Segment>
        </div>
      )
    }

    if (this.state.surveyStatus === 'finish_ok') {

      setTimeout(() => {
        this.setState({
          blockPremixButton: false,
          waiting: false
        })
      }, 5000)

      return (
        <div id="survey-slide">
          {this.state.waiting ? this.waitingAnimation() : this.waitingEnded()}
        </div>
      )
    } else {
      let type = this.state.nextActions.type;
      let validation = this.state.nextActions.validation;
      if (type === 'string' || type === 'integer' || type === 'number') {
        return (
          <>
            <div id="survey-slide">
              <Segment basic textAlign='center'>
                <p className='header-1' dangerouslySetInnerHTML={{__html: t(this.state.currentQuestion)}}/>
              </Segment>
              <Input value={t(this.state.value)} onChange={this.handleInputChange.bind(this)}
                     onFocus={() => this.setState({isError: false})} ref={input => input && input.focus()}/>
              <div>
                <Button onClick={this.answerNext.bind(this)} disabled={this.state.btnNextDisable} floated="right" id="navi-btn-right">dalej
                  <Image alt="arrow right" src={arrow_right}/></Button>
                {(this.state.progress !== 1) &&
                <Button onClick={this.answerPrevious.bind(this)} disabled={this.state.btnPrevDisable} floated="left" id="navi-btn-left">
                  <Image alt="arrow right" src={arrow_left}/>wstecz</Button>}
              </div>
            </div>
          </>
        )
      }
      if (type === 'enum') {
        let radioValues = this.state.nextActions.validation.split(new RegExp("[,|]"));
        return (
          <div id="survey-slide">
            <Segment basic textAlign='center'>
              <p className='header-1' dangerouslySetInnerHTML={{__html: t(this.state.currentQuestion)}}/>
            </Segment>
            <Form>
              {_.map(radioValues, (value, index) => (
                <Form.Field key={value}>
                  <Radio value={t(value)}
                         label={t(value)}
                         checked={this.state.value === value}
                         onClick={() => this.setState({value: value})}
                  />
                </Form.Field>
              ))}
            </Form>
            <div>
              <Button onClick={this.answerNext.bind(this)} disabled={this.state.btnNextDisable} floated="right" id="navi-btn-right">dalej
                <Image alt="arrow right" src={arrow_right}/></Button>
              <Button onClick={this.answerPrevious.bind(this)} disabled={this.state.btnPrevDisable} floated="left" id="navi-btn-left">
                <Image alt="arrow right" src={arrow_left}/>wstecz</Button>
            </div>
          </div>
        )
      }
      if (type === 'password') {
        return (
          <>
            <div id="survey-slide">
              <Segment basic textAlign='center'>
                <p className='header-1' dangerouslySetInnerHTML={{__html: t(this.state.currentQuestion)}}/>
              </Segment>
              <Form.Field>
                <Input value={t(this.state.password)} type="password" onChange={this.handleInputPassword.bind(this)}/>
                {(validation === 'password') && <PasswordStrengthBar password={this.state.password}
                                                                     shortScoreWord={'hasło jest za krótkie'}
                                                                     scoreWords={['słabe', 'słabe', 'słabe', 'dobre', 'silne']}
                                                                     minLength={7}
                />}
              </Form.Field>
              <Divider hidden />
              <p>Hasło powinno składać się z conajmniej dziewięciu znaków. Powinno być kombinacją małych i wielkich liter oraz zawierać cyfry lub znaki specjalne.</p>
              <div>
                <Button onClick={this.answerPassword.bind(this)} disabled={this.state.btnNextDisable} floated="right" id="navi-btn-right">dalej
                  <Image alt="arrow right" src={arrow_right}/></Button>
                <Button onClick={this.answerPrevious.bind(this)} disabled={this.state.btnPrevDisable} floated="left" id="navi-btn-left">
                  <Image alt="arrow right" src={arrow_left}/>wstecz</Button>
              </div>
            </div>
          </>
        )
      }
      if (type === 'multi') {
        let radioValues = this.state.nextActions.validation.split(new RegExp("[,|]"));
        return (
          <div id="survey-slide">
            <Segment basic textAlign='center'>
              <p className='header-1' dangerouslySetInnerHTML={{__html: t(this.state.currentQuestion)}}/>
            </Segment>
            <Form>
              {_.map(radioValues, (value, index) => (
                <Form.Field inline key={value}
                            control={Checkbox}
                            label={t(value)}
                            value={value}
                            onChange={this.handleMulti.bind(this)}
                />
              ))}
            </Form>
            <div>
              <Button onClick={this.sendMulti.bind(this)} floated="right" id="navi-btn-right">dalej
                <Image alt="arrow right" src={arrow_right}/></Button>
              <Button onClick={this.answerPrevious.bind(this)} disabled={this.state.btnPrevDisable} floated="left" id="navi-btn-left">
                <Image alt="arrow right" src={arrow_left}/>wstecz</Button>
            </div>
          </div>
        )
      }
      if (type === 'statement') {
        let radioValues = this.state.nextActions.validation.split(new RegExp("[,|]"));
        return (
          <div id="survey-slide">
            <Segment basic textAlign='center'>
              <p className='header-1' dangerouslySetInnerHTML={{__html: t(this.state.currentQuestion)}}/>
            </Segment>
            <Form>
              {_.map(radioValues, (value, index) => {
                let isRequired = false;
                if (value.charAt(0) === "*") {
                  value = value.slice(1);
                  isRequired = true;
                }
                return (
                  <Form.Field inline key={value}
                              control={Checkbox}
                              label={t(value)}
                              value={value}
                              required={isRequired}
                              onChange={this.handleMulti.bind(this)}
                  />
                );
              })}
            </Form>
            <div>
              <Button onClick={this.sendMulti.bind(this)} disabled={this.state.btnNextDisable} floated="right" id="navi-btn-right">dalej<Image
                alt="arrow right"
                src={arrow_right}/></Button>
              <Button onClick={this.answerPrevious.bind(this)} disabled={this.state.btnPrevDisable} floated="left" id="navi-btn-left">
                <Image alt="arrow right" src={arrow_left}/>wstecz</Button>
            </div>
          </div>
        )
      }
    }
  }

  render() {
    return (
      <SurveyBackground>
        <Segment basic id="survey-main">
          <SEO title="Dobierz suplementację do swoich potrzeb - personalizacja"
            description="Wypełnij ankietę i sprawdź skład swojego personalizowanego suplementu. Prosty, naturalny skład bez wypełniaczy, konserwantów i sztucznych barwników."
          />
          {/*<ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOutRight'>*/}
          <Container>
            <div style={{position: "relative", display: "inline"}}>
              <TransitionGroup>
                <CSSTransition
                  key={this.state.animationKey}
                  classNames={{
                    enter: 'my-enter',
                    enterActive: 'my-active-enter',
                    exit: 'my-exit',
                    exitActive: 'my-active-exit',
                  }}
                  timeout={1000}>
                  <div>{this.makeSurveyQuestion()}</div>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </Container>
          {/*</ScrollAnimation>*/}
          {this.state.showProgressBar &&
          <Segment basic id="progress-bar-container">
            <Progress size="tiny" percent={this.state.progress} color='violet'/>
          </Segment>
          }
        </Segment>
      </SurveyBackground>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey);