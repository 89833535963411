import * as React from "react";
import {IUser} from "../types";
import { Segment} from "semantic-ui-react";
import {connect} from "react-redux";

interface StateProps {
  user: IUser
}

export function mapStateToProps(state: any): StateProps {
  return {
    user: state.user,
  };
}

type Props = StateProps;

interface State {
  loading: boolean,
}

class UserPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount(): void {
    if (this.props.user) {
      this.setState({loading: false});
    }  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.user !== this.props.user) {
      this.setState({loading: false});
    }
  }

  render() {
    if(this.state.loading){
      return (
        <Segment loading={true}/>
      )
    } else {
      return this.props.children;
    }
  }
}

export default connect(mapStateToProps, {})(UserPanel);