import React, {useEffect, useState} from "react";
import "../../css/animation.css";
import { Image } from 'semantic-ui-react';

const bottle = '/img/animation-img/img_1.png';
const premix = '/img/animation-img/img_0.png';

const SurveyAnimation = () => {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(()=>{
       setTimeout(()=>{
           setShowAnimation(true)
       },1000)
    },[])

    if (!showAnimation){
        return (<div className="animation-placeholder"></div>)
    }

    return (
        <div className="survey-animation-fullcontainer">
            <div className="liquid-animation-container">
                <div className="wrapper">
                    <div className="ball"></div>
                    <div className="ball"></div>
                    <div className="ball"></div>
                </div>

                <svg>
                    <defs>
                        <filter id="filter">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="18" result="blur"/>
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 28 -10"
                                           result="filter"/>
                            <feComposite in="SourceGraphic" in2="filter" operator="atop"/>
                        </filter>
                    </defs>
                </svg>

            </div>

            <div className="survey-animation-container">

                <div className="bottle-animation-container">
                    <Image src={bottle} alt="Suplementy" style={{height: '100%'}}/>
                    <div className="open-close-container">
                        <div className="open-close"></div>
                    </div>
                    <div className="premix-falling-container">
                        <div className="premix-falling">
                            <Image src={premix} alt="Premix"/>
                        </div>
                    </div>
                </div>


                <div className="portals-animation-container">

                    <div className="left-portal-container">
                        <div className="left-portal"></div>
                    </div>

                    <div className="top-portal-container">
                        <div className="top-portal"></div>
                    </div>


                    <div className="right-portal-container">
                        <div className="right-portal"></div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default SurveyAnimation;